// * * * * *
// Project name: CAT
// SCSS by: Timo Mitt timo.mitt@nortal.com
// Project started: 05.12.2016
// * * * * *

// Buttons
//------------------------------------------------//

// Btn
.btn {
  font-family: $font-aktiv;
  font-weight: normal;
  font-size: 11px;
  .fa {
    margin-right: 3px;
  }
}

// Btn-link
.btn-link {
  font-weight: normal;
  color: $brand-primary;
  padding: 5px 10px;
  cursor: pointer;
  &.addon {
    border-color: $btn-default-border !important;
  }
}

// Btn-lg
.btn-lg {
  padding: 10px 16px;
  font-size: 14px;
  line-height: $line-height-base;
  border-radius: $border-radius-base;
}

.btn[hidden] {
  visibility: hidden;
}

.btn-group {
  min-width: 91px;
}
