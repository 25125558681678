// * * * * *
// Project name: CAT
// SCSS by: Timo Mitt timo.mitt@nortal.com
// Project started: 05.12.2016
// * * * * *

// Typography
//------------------------------------------------//

// Headings

h1,
h2,
h3,
h4,
h5,
h6,
.row_title,
.row_subtitle {
  font-family: $font-aktiv;
}

h2 {
  overflow: hidden;
  text-overflow: ellipsis;
}

h4 {
  font-weight: 600;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 5px;
  &.collapsed {
    overflow: hidden;
    text-overflow: ellipsis;
    .fa {
      display: inline;
    }
  }
}

h6 {
  color: #3ac4a5;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row_title {
  color: $text-color;
  font-weight: bold;
}

.row_totals {
  font-size: 120%;
}

.row_subtitle {
  color: $brand-primary;
}

.text-muted {
  color: $grayish !important;
  opacity: 1;
  font-size: 12px;
}

::-webkit-input-placeholder {
  color: $grayish;
}

:-moz-placeholder {
  color: $grayish;
  opacity: 1;
}

::-moz-placeholder {
  color: $grayish;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $grayish;
}

:-ms-input-placeholder {
  color: $grayish;
}
