// * * * * *
// Project name: CAT
// SCSS by: Timo Mitt timo.mitt@nortal.com
// Project started: 05.12.2016
// * * * * *

// Panel default
//------------------------------------------------//

.panel-default {
  border: 1px solid #d8e0e6;
  box-shadow: none;
  .panel-heading {
    white-space: nowrap;
    padding: 15px 20px;
  }
}

.panel-group {
  margin-bottom: 0;
}

.panel-button, .panel-button-close {
  position: relative;
  padding: 10px 13px 8px 13px;
  left: -30px;
  > i.fa {
    margin: 0;
  }
}

.panel-button-close:hover > i.fa {
  margin-right: 2px;
  margin-left: 3px;
  @extend .fa-times
}

// Overriding animation

.panel-collapse {
  &.collapsing {
    transition-timing-function: $anim-timing-fn !important;
    transition-duration: $anim-duration-long;
  }
}
