// * * * * *
// Project name: CAT
// SCSS by: Timo Mitt timo.mitt@nortal.com
// Project started: 05.12.2016
// * * * * *

@charset "UTF-8";


// Fonts
//------------------------------------------------//

// Open sans
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

// Raleway
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600,700");
