// * * * * *
// Project name: CAT
// SCSS by: Timo Mitt timo.mitt@nortal.com
// Project started: 05.12.2016
// * * * * *

// Navbar
//------------------------------------------------//

.nav-sidebar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: $navbar-width;
  height: 100%;
  box-shadow: inset -3px 0px 3px 0px rgba(0, 0, 0, .2);
  @include gradient-vertical(#262d34, #38414b);
}

.nav-sidebar {
  height: 100%;
  background: #2c3645;
}

.navbar-collapse {
  padding: 0;
  overflow-y: hidden;
  &.in {
    overflow-y: hidden;
  }
  &.collapsing {
    transition-timing-function: $anim-timing-fn !important;
    transition-duration: $anim-duration;
  }
}

.nav-sidebar__logo {
  width: 100%;
  height: $header-height;
  a {
    display: block;
    width: 100%;
    height: 100%;
    line-height: $header-height;
    vertical-align: middle;
    background: $gray-darker;
  }
  a:hover {
    text-decoration: none;
  }
  img {
    width: auto;
    height: 34px;
    margin: 13px 0 0 19px;
  }
}

.nav-sidebar__title {
  display: none;
}

.nav-sidebar__navs {
  margin: 0 0 0;
  padding: 0;
  list-style: none;
}

.nav-sidebar__item {
  &.active {
    .nav-sidebar__link {
      background-color: $gray-lighter;
      color: #293038;
      font-weight: bold;
      @include hocus() {
        color: #293038;
      }
    }
  }
}

.nav-sidebar__link {
  display: block;
  color: #8c9da1;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  @include hocus() {
    color: #8c9da1;
    text-decoration: none;
  }
  i {
    width: $font-size-icon + 4;
    font-size: $font-size-icon;
    text-align: center;
  }
  span {
    display: none;
  }
}

.nav-sidebar__item {
  .nav-sidebar__link {
    padding: 15px 10px;
    border-bottom: 1px solid #344053;
  }
}

.nav-sidebar__toggle {
  display: none;
  float: right;
  margin: 0;
  margin-right: 2px;
  padding: $spacing-unit-base + 1;
  border: none;
  background: none;
  box-shadow: none;
  color: #fff;
  font-size: $font-size-large;
  &:focus {
    outline: none;
  }
}

.navbar-fixed-top {
  z-index: 3;
  position: absolute;
  margin-right: 15px;
  top: $header-height;
  background-color: $gray-light;
  padding-right: $spacing-unit-base * 1.7;
  padding-left: $spacing-unit-base * 1.5;
  .text-right {
    margin-bottom: 5px;
  }

  .row > div button {
    padding-right: $spacing-unit-base;
    padding-left: $spacing-unit-base;
    height: $input-height-base;
  }
  .btn-group {
    min-width: unset;
  }
}

@media only screen and (min-width: 1820px) {
  .navbar-fixed-top {
    .text-right {
      margin-top: 17px;
    }
  }
}

// Mobile
//------------------------------------------------//

@media only screen and (max-width: $screen-xs-max) {
  .nav-sidebar {
    min-height: $header-height-mobile;
  }
  .nav-sidebar-wrapper {
    top: $header-height-mobile;
    z-index: 1500;
    width: 100%;
    height: auto;
    max-height: 100%;
    overflow-y: auto;
  }
  .navbar-collapse {
    width: 100%;
    padding: 0 0;
    border: none;
    box-shadow: none;
    ul {
      list-style-type: none;
      li {
        float: right;
        a {
          padding-top: 5px !important;
          span {
            display: inline-block;
            font-size: 10px;
          }
        }
      }
    }
  }
  .nav-sidebar__logo {
    display: none;
  }
  .nav-sidebar__title {
    display: inline-block;
    height: $header-height-mobile;
    padding: floor($spacing-unit-base * 1.5);
    color: #fff;
    font-size: $font-size-base - 1;
    font-weight: bold;
    text-transform: uppercase;
    i {
      margin-right: $spacing-unit-base - 1;
      font-size: $font-size-icon;
    }
  }
  .nav-sidebar__navs {
    margin: 0;
  }
  .nav-sidebar__toggle {
    display: block;
  }
  .nav-sidebar__link {
    i {
      margin-right: 9px;
    }
    + .tooltip * {
      display: none;
    }
  }
  #fixedTop .text-right {
    margin-bottom: 10px;
  }
}
