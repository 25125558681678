.dropdown-menu {
  padding: 0;
  border-color: $gray-light;
  box-shadow: 0 2px 2px 1px rgba(116, 134, 139, .1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  > li {
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid $gray-light;
    }
    > a {
      padding: 15px ($spacing-unit-base * 2);
      color: $brand-info;
    }
  }
  > li:hover {
    .btn-link {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
      background-color: transparent;
    }
  }
  i {
    margin-right: $spacing-unit-base;
    font-size: $font-size-icon;
  }
}

.dropdown-menu-right {
  z-index: 5;
}
