.wrapper {
  width: 100%;
  &.full-height {
    height: 100%;
  }
  &.has-scroll {
    overflow-y: auto;
  }
}

.main {
  position: absolute;
  top: 0;
  right: 0;
  width: 88%;
  width: calc(100% - #{$navbar-width});
  height: 100%;
  overflow: hidden;
}

.main__area {
  width: 100%;
  height: 92%;
  height: calc(100% - #{$header-height});
  overflow-x: hidden;
  overflow-y: auto;
}

.app {
  padding-left: 20px;
  .panel{
    cursor: pointer;
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .main {
    width: 100%;
  }
  .main__area {
    $temp: $header-height-mobile * 2;
    height: 94%;
    height: calc(100% - #{$temp});
    margin-top: $header-height-mobile;
  }
}
