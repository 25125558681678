// * * * * *
// Project name: CAT
// SCSS by: Timo Mitt timo.mitt@nortal.com
// Project started: 05.12.2016
// * * * * *


// Helpers
//------------------------------------------------//

// ng-cloak styles in order to prevent flickering on load

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}
