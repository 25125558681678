// * * * * *
// Project name: CAT
// SCSS by: Timo Mitt timo.mitt@nortal.com
// Project started: 05.12.2016
// * * * * *


// Scaffolding
//------------------------------------------------//

html,
body {
	height: 100%;
	overflow: hidden;
}