.userinfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    margin-left: 10px;
  }
  > span {
    margin-top: 5px;
    margin-bottom: 5px;
    > * {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    > h5 {
      text-transform: uppercase;
    }
  }
}