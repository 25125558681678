login {
  .centerize {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .btn-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}