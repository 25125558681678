$navbar-inverse-link-color: #5AADBB;
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.thumbnail {
  height: 200px;

  img.pull-right {
    width: 50px;
  }
}

$fa-font-path: "assets/fonts";
@import "assets/scss/font-awesome.scss";
@import "../styles/main";
