// * * * * *
// Project name: CAT
// SCSS by: Timo Mitt timo.mitt@nortal.com
// Project started: 05.12.2016
// * * * * *


// Bootstrap
//------------------------------------------------//

@import "variables";

// @import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap/normalize";
@import "bootstrap/print";
//@import "bootstrap/glyphicons";
@import "bootstrap/scaffolding";
@import "bootstrap/type";
//@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
//@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
//@import "bootstrap/pager";
@import "bootstrap/labels";
//@import "bootstrap/badges";
//@import "bootstrap/jumbotron";
//@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
//@import "bootstrap/progress-bars";
//@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/panels";
//@import "bootstrap/responsive-embed";
//@import "bootstrap/wells";
@import "bootstrap/close";
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
//@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";


// Project
//------------------------------------------------//

// Mixins
@import "modules/_mixins";

// Components
@import "modules/_buttons";
@import "modules/_dropdowns";
@import "modules/_fonts";
@import "modules/_header";
@import "modules/_helpers";
@import "modules/_layout";
@import "modules/_login";
@import "modules/_navbar";
@import "modules/_panels";
@import "modules/_scaffolding";
@import "modules/_tooltip";
@import "modules/_typography";
@import "modules/_userinfo";

// Overloading "glyphicon" class with "fa".
.glyphicon {

    @extend .fa;

    // Overloading "glyphicon-chevron-left" with "fa-arrow-left".
    &.glyphicon-chevron-left {
        @extend .fa-chevron-left;
    }

    // Overloading "glyphicon-chevron-right" with "fa-arrow-right".
    &.glyphicon-chevron-right {
        @extend .fa-chevron-right;
    }
    
    &.glyphicon-remove {
        @extend .fa-times;
        color: $gray;
    }
}

body::-webkit-scrollbar {
    width: 1em;
}
